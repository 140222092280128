<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>班级管理</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">班级管理</span>
          <bjgl v-if="activeKey === '1'" />
        </a-tab-pane>
        <!-- <a-tab-pane key="2">
          <span slot="tab">开课记录</span>
          <kkjl v-if="activeKey === '2'" />
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">课后作业</span>
          <khzy />
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">班级学员查询</span>
          <bjxy v-if="activeKey === '4'" />
        </a-tab-pane>
        <a-tab-pane key="5">
          <span slot="tab">班级异动</span>
          <bjyd />
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import bjgl from './bjgl/index'
// import kkjl from './kkjl/index'
// import khzy from './khzy'
// import bjxy from './bjxy/index'
// import bjyd from './bjyd'

export default {
  name: 'Classes',
  components: {
    bjgl,
    // kkjl,
    // khzy,
    // bjxy,
    // bjyd
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  created() {
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

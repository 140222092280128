<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-button type="primary" icon="plus" @click="showEditModal()">新增</a-button>
          </a-form-item>
          <a-form-item>
            <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>班级名称</span>
              </template>
              <a-input v-model.trim="searchParams.search.class_name" placeholder="班级名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-popover placement="bottom" trigger="click" v-model="searchVisible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <a-form-item class="block-line" label="课程科目" >
                      <a-select v-model="searchParams.search.course_cate_id" showSearch allowClear placeholder="请选择" :filterOption="filterOption">
                        <a-select-option v-for="(item, index) of cCategorys" :key="index" :value="item.course_cate_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="授课老师" >
                      <a-select v-model="searchParams.search.tutor_id" showSearch allowClear placeholder="请选择" :filterOption="filterOption">
                        <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="班主任" >
                      <a-select v-model="searchParams.search.cc_id" showSearch allowClear placeholder="请选择" :filterOption="filterOption">
                        <a-select-option v-for="(item, index) of consultants" :key="index" :value="item.course_consultant_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="周几授课" >
                      <a-select v-model="searchParams.search.weekly" multiple allowClear placeholder="请选择">
                        <a-select-option v-for="(item, index) of classWeeklys" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="上课校区" >
                      <a-select v-model="searchParams.search.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear placeholder="请选择" showSearch :filterOption="filterOption">
                        <a-select-option v-for="(item, index) of studios" :key="index" :value="item.studio_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="班级类型" >
                      <a-select v-model="searchParams.search.class_type" allowClear placeholder="请选择">
                        <a-select-option v-for="(item, index) of classTypes" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="班级状态" >
                      <a-select v-model="searchParams.search.status" allowClear placeholder="请选择">
                        <a-select-option v-for="(item, index) of classStatus" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="class_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1300 }" @change="handleChange">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="className" slot-scope="class_name, record">
          <a @click="showDetailModal(record,'1')">{{ class_name }}</a>
        </template>
        
        <template slot="classPercent" slot-scope="class_percent, record">
          <a-progress 
          :stroke-color="{
            '0%': '#00cca2',
            '100%': '#00cca2',
          }"
          :percent="Number(class_percent)" size="small" :showInfo="true" />
        </template>

        <template slot="classRule" slot-scope="class_rule">
          <div v-for="(item, index) of class_rule" :key="index">{{ item }}</div>
        </template>

        <template slot="class_count" slot-scope="class_count,record">
          <a @click="showDetailModal(record,'1')">{{class_count}}</a>
        </template>

        <template slot="class_had_course_count" slot-scope="class_had_course_count,record">
          <a @click="showDetailModal(record,'2')">{{class_had_course_count}} / {{record.class_total_course_count}}</a>
        </template>

        <template slot="action" slot-scope="text,record">
          
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="showEditModal(record)">
                班级信息
              </a-menu-item>
              <a-menu-item key="2" @click="showDetailModal(record,'1')">
                班级学员
              </a-menu-item>
              <a-menu-item key="3" @click="copyEditModal(record)">
                复制开班
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <!-- <a-divider type="vertical" />
          <a slot="action" href="javascript:;">课程安排</a>
          <a-divider type="vertical" />
          <a slot="action" href="javascript:;">查看</a> -->
        </template>

      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>

          </a-pagination>
      </div>
    <editModal v-if="visible" :isEdit='isEdit' :copy='copyData' :item="modalData"/>
    <detailModal v-if="detailVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '班级名称', width:'230px', dataIndex: 'class_name', key: 'class_name', scopedSlots: { customRender: 'className' }, fixed: 'left' ,ellipsis:true},
  { title: '上课校区', dataIndex: 'studio_id', key: 'classes.studio_id' ,ellipsis:true,sorter:()=>{}},
  { title: '课程安排', dataIndex: 'class_rule', key: 'class_rule',scopedSlots: { customRender: 'classRule' }},
  { title: '课程科目', dataIndex: 'course_cate_id', key: 'classes.course_cate_id',ellipsis:true,sorter:()=>{}},
  { title: '授课老师', width: '80px', dataIndex: 'tutor_id', key: 'tutor_id' ,ellipsis:true},
  { title: '班主任', width: '80px', dataIndex: 'cc_id', key: 'classes.cc_id' ,ellipsis:true},
  { title: '班级类型', width: '90px', dataIndex: 'class_type', key: 'classes.class_type' ,ellipsis:true,sorter:()=>{}},
  { title: '班级学员', width: '100px', dataIndex: 'class_count', key: 'classes.class_count',align:'center', scopedSlots: { customRender: 'class_count' },sorter:()=>{}},
  { title: '课程进度', width: '100px', dataIndex: 'class_had_course_count', key: 'class_had_course_count',scopedSlots: { customRender: 'class_had_course_count' } ,align:"center",sorter:()=>{}},
  { title: '满班率', width: '120px', dataIndex: 'class_percent', key: 'class_percent',scopedSlots: { customRender: 'classPercent' } ,align:'center',sorter:()=>{}},
  { title: '最近排课', dataIndex: 'class_start', key: 'class_start',align:'center',sorter:()=>{}},
  { title: '操作', key: 'operation', fixed: 'right', width: 60, scopedSlots: { customRender: 'action' }}
]


import tableMixins from '@/common/mixins/table'
import editModal from './editModal'
import detailModal from './detail/index'
import cItems from '@/utils/items'
import moment from 'moment'

export default {
  name: 'bjgl',
  mixins: [ tableMixins ],
  components: {
    editModal,
    detailModal
  },

  data() {
    return {
      loading: false,
      columns,
      studios: [],
      cCategorys: [],
      teachers: [],
      consultants: [],
      classTypes: cItems.classTypes,
      classStatus: cItems.class_status,
      classWeeklys: cItems.classWeeklys,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      detailVisible: false,
      exportLoading: false,
    }
  },

  created() {
    this.getStudio()
    this.cCategoryDetailAction()
    this.getTeacher()
    this.getConsultant()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList() {
      this.loading = true
      let res = await this.$store.dispatch('classesListAction', this.searchParams)
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },

    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async cCategoryDetailAction() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.cCategorys = res.data
    },
    async getTeacher() {
      let res = await this.$store.dispatch('searchTeacherAction', {})
      this.teachers = res.data
    },
    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },
    showDetailModal(item,num) {
      this.modalData = item
      this.modalData.activeIndex = num
      this.detailVisible = true
    },
    hideDetailModal() {
      this.detailVisible = false
    },
    copyEditModal(record){
      this.visible = true
      this.isEdit = 2
      this.modalData = null
      this.copyData = record
    },
    handleChange(pagination, filters, sorter){
      if(sorter.order){
          if(sorter.order === "ascend"){
              this.searchParams['sort'] = `${sorter.field}`
          }else{
              this.searchParams['sort'] = `-${sorter.field}`
          }
      }else{
          this.searchParams['sort'] = ''
          }
      this.getList()
    },
    async toExport(){
      this.exportLoading = true
      let res = await this.$store.dispatch('classesExportAction', this.searchParams)
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `班级列表.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    }
  }
}
</script>
